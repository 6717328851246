import React, { useState, useEffect } from 'react'
import { CSVLink } from "react-csv"
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'

import { database } from '../firebase/firebase'

const useStyles = makeStyles((theme) => ({
  dipTable: {
    emptyCells: 'show',
    margin: '0 auto 40px auto',
    maxWidth: '800px',
    '@media print' : {
      margin: '0 0 20px',
      maxWidth: 'none',
      '& td, th': {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: '2px 4px',
      }
    }
  },
  tableCell: {
    padding: '6px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '4px 6px',
    },
  },
  siteName: {
    fontSize: '16px',
    fontWeight: 'normal',
  },
  sfl: {
    backgroundColor: '#ffb74d'
  },
  dip: {
    backgroundColor: '#ffef62'
  },
  rem: {
    backgroundColor: '#81c784'
  },
  actions: {
    '& button': {
      margin: '0 6px',
    },
    '@media print' : {
      display: 'none'
    }
  },
}))

const AdminData = ({ filters }) => {
  const classes = useStyles()
  const [sites, setSites] = useState([])
  const [dips, setDips] = useState([])
  const csvData = []

  useEffect(() => {
    database
    .ref(`sites`)
    .once('value')
    .then(snap => {
      const rows = []
      snap.forEach(childSnap => {
        rows.push({
          name: childSnap.key,
          ...childSnap.val() 
        })
      })
      setSites(rows)
    })
  }, [])

  useEffect(() => {
    database
    .ref(`dips/${filters.date}`)
    .once('value')
    .then(snap => {
      const rows = []
      snap.forEach(childSnap => {
        childSnap.forEach(dipNode => {
          if (dipNode.key === filters.time) {
            rows.push(dipNode.val())
          }
        })
        // if (childSnap.val().time === filters.time) {
        //   rows.push({
        //     id: childSnap.key,
        //     ...childSnap.val() 
        //   })
        // }
      })
      setDips(rows)
    })
  }, [filters])

  return (
    <>
      {sites.map(site => {
        const dipsForSite = dips.find(dip => dip.site === site.name)

        const ulpSum = dipsForSite ? dipsForSite.ulp ? dipsForSite.ulp : 0 : 0
        const pulp98Sum = dipsForSite ? dipsForSite.pulp98 ? dipsForSite.pulp98 : 0 : 0
        const e10Sum = dipsForSite ? dipsForSite.e10 ? dipsForSite.e10 : 0 : 0
        const diesel1Sum = dipsForSite ? dipsForSite.diesel1 ? dipsForSite.diesel1 : 0 : 0
        const diesel2Sum = dipsForSite ? dipsForSite.diesel2 ? dipsForSite.diesel2 : 0 : 0

        const sflValues = {
          ulp: site.ulp >= 0 ? site.ulp : '',
          pulp98: site.pulp98 >= 0 ? site.pulp98 : '',
          e10: site.e10 >= 0 ? site.e10 : '',
          diesel1: site.diesel1 >= 0 ? site.diesel1 : '',
          diesel2: site.diesel2 >= 0 ? site.diesel2 : ''
        }

        const dipValues = {
          ulp: site.ulp >= 0 ? ulpSum : '',
          pulp98: site.pulp98 >= 0 ? pulp98Sum : '',
          e10: site.e10 >= 0 ? e10Sum : '',
          diesel1: site.diesel1 >= 0 ? diesel1Sum : '',
          diesel2: site.diesel2 >= 0 ? diesel2Sum : ''
        }

        const remValues = {
          ulp: site.ulp >= 0 ? parseInt(site.ulp) - ulpSum : '',
          pulp98: site.pulp98 >= 0 ? parseInt(site.pulp98) - pulp98Sum : '',
          e10: site.e10 >= 0 ? parseInt(site.e10) - e10Sum : '',
          diesel1: site.diesel1 >= 0 ? parseInt(site.diesel1) - diesel1Sum : '',
          diesel2: site.diesel2 >= 0 ? parseInt(site.diesel2) - diesel2Sum : ''
        }

        const headerCSV = [site.name,'ULP','PULP98','E10','Diesel','Diesel2'] 
        const sflCSV = ['SFL', sflValues.ulp, sflValues.pulp98, sflValues.e10, sflValues.diesel1, sflValues.diesel2]
        const dipCSV = ['DIP', dipValues.ulp, dipValues.pulp98, dipValues.e10, dipValues.diesel1, dipValues.diesel2]
        const remCSV = ['ULLAGE', remValues.ulp, remValues.pulp98, remValues.e10, remValues.diesel1, remValues.diesel2]
        csvData.push(headerCSV)
        csvData.push(sflCSV)
        csvData.push(dipCSV)
        csvData.push(remCSV)

        return (
          <Table size="small" key={site.name} className={classes.dipTable}>
            <colgroup>
              <col style={{width:'35%'}}/>
              <col style={{width:'13%'}}/>
              <col style={{width:'13%'}}/>
              <col style={{width:'13%'}}/>
              <col style={{width:'13%'}}/>
              <col style={{width:'13%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell className={`${classes.tableCell} ${classes.siteName}`}>{site.name}</TableCell>
                <TableCell className={classes.tableCell} align="right">{site.hasOwnProperty('ulp') ? 'ULP' : ' '}</TableCell>
                <TableCell className={classes.tableCell} align="right">{site.hasOwnProperty('pulp98') ? 'PULP98' : ' '}</TableCell>
                <TableCell className={classes.tableCell} align="right">{site.hasOwnProperty('e10') ? 'E10' : ' '}</TableCell>
                <TableCell className={classes.tableCell} align="right">{site.hasOwnProperty('diesel1') ? 'Diesel' : ' '}</TableCell>
                <TableCell className={classes.tableCell} align="right">{site.hasOwnProperty('diesel2') ? 'Diesel2' : ' '}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell}>SFL</TableCell>
                {Object.keys(sflValues).map((key, i) => (
                  <TableCell key={i} className={`${classes.tableCell} ${classes.sfl}`} align="right">{sflValues[key]}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell}>DIP</TableCell>
                {Object.keys(dipValues).map((key, i) => (
                  <TableCell key={i} className={`${classes.tableCell} ${classes.dip}`} align="right">{dipValues[key]}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell}>ULLAGE</TableCell>
                {Object.keys(remValues).map((key, i) => (
                  <TableCell key={i} className={`${classes.tableCell} ${classes.rem}`} align="right">{remValues[key]}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
      )})}
      <Container align="center" className={classes.actions}>
        <CSVLink data={csvData} filename={`dip-export-${filters.date}-${filters.time}.csv`}>
          <Button variant="contained" color="primary">
            Export as CSV
          </Button>
        </CSVLink>
        <Button variant="contained" color="primary" onClick={() => window.print()}>
          Print Tables
        </Button>
      </Container>
    </>
  )
}
 
export default AdminData
