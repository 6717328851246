import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AddBoxIcon from '@material-ui/icons/AddBox'
import LockIcon from '@material-ui/icons/Lock'
import ListIcon from '@material-ui/icons/List';

import { firebase, signOut } from '../firebase/firebase'
import { AuthContext } from '../contexts/AuthContext'
import DialogModal from './DialogModal'

const useStyles = makeStyles(theme => ({
  list: {
    width: 200,
    [theme.breakpoints.up('sm')]: {
      width: 300,
    },
  },
  linkText: {
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
  },
}))

const MenuDrawer = ({ role, page }) => {
  const classes = useStyles()
  const { email, setPage } = useContext(AuthContext)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [dialogText, setDialogText] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)

  const handlePasswordReset = () => {
    firebase.auth().sendPasswordResetEmail(email).then(function() {
      setDialogText("A password change email has been sent. Please check your email and follow the link provided.")
      setDialogOpen(true)
    }).catch(() => {
      setDialogText("Unable to send password reset email. Please try again later.")
      setDialogOpen(true)
    })
  }

  return (
    <div>
      <Button onClick={() => setDrawerOpen(true)}><MenuIcon /></Button>
      <Drawer anchor={'right'} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <List className={classes.list}>
          <ListItem button onClick={signOut}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText>Sign Out</ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          {role === 'admin' && (
            page === 'admin' ? (
                <ListItem button onClick={() => setPage('user')}>
                  <ListItemIcon><AddBoxIcon /></ListItemIcon>
                  <ListItemText>Add DIP</ListItemText>
                </ListItem>
            ) : (
                <ListItem button onClick={() => setPage('admin')}>
                  <ListItemIcon><ListIcon /></ListItemIcon>
                  <ListItemText>Admin Data</ListItemText>
                </ListItem>
            )
          )}
          <ListItem button onClick={handlePasswordReset}>
            <ListItemIcon><LockIcon /></ListItemIcon>
            <ListItemText>Change Password</ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <DialogModal
        dialogOpen={dialogOpen}
        handleCloseDialog={() => {
          setDialogOpen(false)
          setDialogText('')
          setDrawerOpen(false)
        }}
        text={dialogText}
        closeText="Ok"
      />
    </div>
  )
}

export default MenuDrawer
