import React, { Component, createContext } from 'react'

export const AuthContext = createContext()

class AuthContextProvider extends Component {
  state = {
    isAuthenticated: false,
    uid: '',
    email: '',
    role: '',
    page: ''
  }
  setAuth = ({ isAuthenticated, uid, email, role, page }) => {
    this.setState({ isAuthenticated, uid, email, role, page })
  }
  setPage = page => this.setState({ page })
  clearAuth = () => {
    this.setState({
      isAuthenticated: false,
      uid: '',
      email: '',
      role: '',
      page: ''
    })
  }
  render() { 
    return (
      <AuthContext.Provider value={{
        ...this.state,
        setAuth: this.setAuth,
        clearAuth: this.clearAuth,
        setPage: this.setPage
      }}>
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
 
export default AuthContextProvider
