import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(() => ({
  dialogContent: {
    paddingTop: '40px!important',
    paddingRight: '60px',
    paddingLeft: '60px',
  },
  dialogActions: {
    paddingBottom: '40px!important',
  },
}))

const DialogModal = ({ dialogOpen, handleCloseDialog, text, closeText}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleCloseDialog} color="primary" autoFocus>
          {closeText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
 
export default DialogModal