import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import UserForm from './UserForm'

import { AuthContext } from '../contexts/AuthContext'
import Footer from './Footer'
import Header from './Header'

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  pageHeader: {
    paddingBottom: theme.spacing(5)
  },
  toolbar: {
    justifyContent: 'flex-end'
  },
}))

const UserPage = () => {
  const classes = useStyles()
  const { role } = useContext(AuthContext)

  return (
    <>
      <CssBaseline />
      <Header role={role} page="user" />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center" className={classes.pageHeader}>
            DIPS Entry
          </Typography>
          <>
            <UserForm />
          </>
        </Paper>
        <Footer />
      </main>
    </>
  )
}
 
export default UserPage
