import React, { useContext, useEffect } from 'react'

import { AuthContext } from './contexts/AuthContext'
import { firebase, database } from './firebase/firebase'
import AdminPage from './components/AdminPage'
import UserPage from './components/UserPage'
import SignInPage from './components/SignInPage'

const App = () => {
  const { isAuthenticated, role, page, setAuth, clearAuth } = useContext(AuthContext)
  
  useEffect(() => {
    // Listen for firebase login/logout and update auth slice
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        database.ref(`users/${user.uid}/`).once('value').then(snapshot => {
          const role = snapshot.val() || 'user'
          setAuth({
            isAuthenticated: true,
            uid: user.uid,
            email: user.email,
            role,
            page: role
          })
        }).catch((error) => {
          console.log('Error fetching data', error)
        })
      } else {
        clearAuth()
      }
    })
  }, [])

  return (
    <>
    {isAuthenticated ? (
      (role === 'admin' && page === 'admin') ? (
        <AdminPage />
      ) : (
        <UserPage />
      )
    ) : (
      <SignInPage />
    )}
    </>
  )
}

export default App
