import React, { useState, useContext, useEffect } from 'react'
import { DateTime } from 'luxon'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import { database } from '../firebase/firebase'
import { AuthContext } from '../contexts/AuthContext'
import DialogModal from './DialogModal'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  marginBtm: {
    
  },
  error: {
    color: '#f44336'
  }
}))

const UserForm = () => {
  const classes = useStyles()
  const { uid } = useContext(AuthContext)

  const now = DateTime.now()
  const [dipValues, setDipValues] = useState({
    date: now.setZone('Australia/Sydney').toISODate(),
    time: 'am',
    site: '',
    ulp: '',
    pulp98: '',
    e10: '',
    diesel1: '',
    diesel2: ''
  })

  useEffect(() => {
    database
    .ref(`dips/${dipValues.date}/${dipValues.site}/${dipValues.time}`)
    .once('value')
    .then(snap => {
      setDipValues({
        ...dipValues,
        ulp: snap.val() ? snap.val().ulp || '' : '',
        pulp98: snap.val() ? snap.val().pulp98 || '' : '',
        e10: snap.val() ? snap.val().e10 || '' : '',
        diesel1: snap.val() ? snap.val().diesel1 || '' : '',
        diesel2: snap.val() ? snap.val().diesel2 || '' : ''
      })
    })
  }, [dipValues.date, dipValues.time, dipValues.site])

  const [siteError, setSiteError] = useState('')
  const [dateError, setDateError] = useState('')
  const [timeError, setTimeError] = useState('')
  const [dipError, setDipError] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false) 

  const handleDipValues = e => {
    setDipError('')
    const { value, name } = e.target
    if (name === 'ulp' ||
        name === 'pulp98' ||
        name === 'e10' ||
        name === 'diesel1' ||
        name === 'diesel2') {
      if (!isNaN(value)) setDipValues({ ...dipValues, [name]: value })
    } else {
      setDipValues({ ...dipValues, [name]: value })
    }
    
  }

  const handleSubmit = e => {
    e.preventDefault()

    const ref = `dips/${dipValues.date}/${dipValues.site}/${dipValues.time}`

    if (!dipValues.site) setSiteError('You must select a site')
    else if (!dipValues.time) setTimeError('You must select a time')
    else if (DateTime.fromISO(dipValues.date)  > now.setZone('Australia/Sydney').toISODate()) setDateError('You must select a valid date')
    else if (dipValues.ulp === '' && dipValues.pulp98 === '' && dipValues.e10 === '' && dipValues.diesel1 === '' && dipValues.diesel2 === '') {
      setDipError('You must enter at least one DIP value')
    } else {
      database.ref(ref).update({
        createdAt: DateTime.now().toISO(),
        uid,
        ...dipValues
      })
      .then(() => {
        setDialogOpen(true)
      })
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={8} sm={4}>
            <FormControl style={{ width: '100%' }}>
              <TextField
                id="date"
                label="Date"
                type="date"
                name="date"
                defaultValue={dipValues.date}
                onChange={e => {
                  handleDipValues(e)
                  setDateError('')
                }}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {dateError && <FormHelperText className={classes.error}>{dateError}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={4} className={classes.marginBtm}>
            <FormControl className={classes.formControl}>
              <InputLabel id="time-select-label">Time</InputLabel>
              <Select
                labelId="time-select-label"
                id="time-select"
                style={{ width: '100%' }}
                name="time"
                value={dipValues.time}
                onChange={e => {
                  handleDipValues(e)
                  setTimeError('')
                }}
                error={!!timeError}
              >
                <MenuItem value="am">AM</MenuItem>
              </Select>
              {timeError && <FormHelperText className={classes.error}>{timeError}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="site-select-label">Site</InputLabel>
              <Select
                labelId="site-select-label"
                id="site-select"
                style={{ width: '100%' }}
                name="site"
                value={dipValues.site}
                onChange={e => {
                  handleDipValues(e)
                  setSiteError('')
                }}
                error={!!siteError}
              >
                <MenuItem value="COLLOSSEUM">COLLOSSEUM</MenuItem>
                <MenuItem value="D-BAY">D-BAY</MenuItem>
                <MenuItem value="GAYNDAH">GAYNDAH</MenuItem>
                <MenuItem value="GOOMERI">GOOMERI</MenuItem>
                <MenuItem value="GORE">GORE</MenuItem>
                <MenuItem value="MALENY">MALENY</MenuItem>
                <MenuItem value="MONKLAND">MONKLAND </MenuItem>
                <MenuItem value="GOOMBOORIAN">GOOMBOORIAN</MenuItem>
                <MenuItem value="TANAWHA">TANAWHA</MenuItem>
                <MenuItem value="THANGOOL">THANGOOL</MenuItem>
                <MenuItem value="WARRA">WARRA</MenuItem>
              </Select>
              {siteError && <FormHelperText className={classes.error}>{siteError}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm>
            <TextField
              name="ulp"
              value={dipValues.ulp}
              onChange={handleDipValues}
              label="ULP"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm>
            <TextField
              name="pulp98"
              value={dipValues.pulp98}
              onChange={handleDipValues}
              label="PULP98"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm>
            <TextField
              name="e10"
              value={dipValues.e10}
              onChange={handleDipValues}
              label="E10"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm>
            <TextField
              name="diesel1"
              value={dipValues.diesel1}
              onChange={handleDipValues}
              label="Diesel"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm>
            <TextField
              name="diesel2"
              value={dipValues.diesel2}
              onChange={handleDipValues}
              label="Diesel 2"
              fullWidth
            />
          </Grid>
          {dipError && (
            <Grid item xs={12}>
              <FormHelperText className={classes.error}>{dipError}</FormHelperText>
            </Grid>
          )}
          <Grid item xs={12} className={classes.marginBtm}>
            <Button type="submit" variant="contained"  color="primary">Save</Button>
          </Grid>
        </Grid>
      </form>
      <DialogModal
        dialogOpen={dialogOpen}
        handleCloseDialog={() => setDialogOpen(false)}
        text="Your dips entries have been saved"
        closeText="Ok"
      />
    </>
  )
}

export default UserForm
