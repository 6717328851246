import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyBpfE8Rkq2qddlAbdO6E8TWV8TIuRUZmh8",
  authDomain: "dips-d0310.firebaseapp.com",
  databaseURL: "https://dips-d0310-default-rtdb.firebaseio.com",
  projectId: "dips-d0310",
  messagingSenderId: "344347986022",
  appId: "1:344347986022:web:219a6663b6fbd892fc85a7"
}

firebase.initializeApp(firebaseConfig)

const database = firebase.database()
const googleAuthProvider = new firebase.auth.GoogleAuthProvider()

// Secondary App for admin account creation
const secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary")

const signOut = () => {
  firebase.auth().signOut()
}

export { firebase, googleAuthProvider, secondaryApp, signOut, database }
