import React from 'react'
import Typography from '@material-ui/core/Typography'

const Footer = ({ variant = 'slim' }) => {
  return (
    <footer style={{ margin: '20px 0 40px' }}>
      <Typography variant="body2" color="textSecondary" align="center">
        {`Copyright ©${new Date().getFullYear()} Property of Sunstate Petroleum Pty Ltd.`}
      </Typography>
      {variant === 'full' && (
        <>
          <Typography variant="body2" color="textSecondary" align="center">Authorised access only</Typography>
          <Typography variant="body2" color="textSecondary" align="center">All user access is recorded</Typography>
        </>
      )}
    </footer>)
}
 
export default Footer