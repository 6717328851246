import React, { useContext, useState } from 'react'
import { DateTime } from 'luxon'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { AuthContext } from '../contexts/AuthContext'
import AdminData from './AdminData'
import Footer from './Footer'
import Header from './Header'

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    '@media print' : {
      boxShadow: 'none',
      margin: 0,
      padding: 0,
    }
  },
  pageHeader: {
    paddingBottom: theme.spacing(5),
    '@media print' : {
      display: 'none'
    }
  },
  printHeader: {
    display: 'none',
    '@media print' : {
      display: 'block',
      marginBottom: '10px',
    }
  },
  toolbar: {
    justifyContent: 'flex-end'
  },
  filters: {
    margin: '0 auto 30px auto',
    maxWidth: '600px',
    '@media print' : {
      display: 'none'
    }
  },
  formControl: {
    width: '100%'
  },
}))

const AdminPage = () => {
  const classes = useStyles()
  const { role } = useContext(AuthContext)
  const now = DateTime.now()
  const [filters, setFilters] = useState({
    date: now.setZone('Australia/Sydney').toISODate(),
    time: 'am'
  })

  return (
    <>
      <CssBaseline />
      <Header role={role} page="admin" />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center" className={classes.pageHeader}>
            Admin Data
          </Typography>
          <Typography component="h1" className={classes.printHeader}>
            DIP Data - {filters.date + ' ' + filters.time.toUpperCase()}
          </Typography>
          <Grid container spacing={3} className={classes.filters}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="date"
                  label="Date"
                  type="date"
                  name="date"
                  defaultValue={filters.date}
                  onChange={e => setFilters({ ...filters, date: e.target.value })}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="time-select-label">Time</InputLabel>
                <Select
                  labelId="time-select-label"
                  id="time-select"
                  style={{ width: '100%' }}
                  name="time"
                  value={filters.time}
                  onChange={e => setFilters({ ...filters, time: e.target.value })}
                >
                  <MenuItem value="am">AM</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <AdminData filters={filters} />
        </Paper>
        <Footer />
      </main>
    </>
  )
}
 
export default AdminPage
