import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import MenuDrawer from './MenuDrawer'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    '@media print' : {
      display: 'none'
    }
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const Header = ({ role, page }) => {
  const classes = useStyles()

  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit" noWrap>Sunstate Petroleum</Typography>
        <MenuDrawer role={role} page={page} />
      </Toolbar>
    </AppBar>
  )
}
 
export default Header
