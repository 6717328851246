import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import FormHelperText from '@material-ui/core/FormHelperText'

import { firebase } from '../firebase/firebase'
import logo from '../images/logo.png'
import Footer from './Footer'
import DialogModal from './DialogModal'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  logo: {
    maxWidth: '80%',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      maxWidth: '400px',
      paddingBottom: theme.spacing(5),
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textRight: {
    textAlign: 'right'
  },
  error: {
    color: '#f44336'
  },
}))

const SignIn = () => {
  const classes = useStyles()
  const [userCredentials, setUserCredentials] = useState({ email: "", password: "" })
  const [passwordReset, setPasswordReset] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogText, setDialogText] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [signInError, setSignInError] = useState(false)

  const handleCredentials = (e) => {
    setEmailError(false)
    const { value, name } = e.target
    setUserCredentials({ ...userCredentials, [name]: value })
  }

  const handleLogin = e => {
    e.preventDefault()
    firebase.auth().signInWithEmailAndPassword(userCredentials.email, userCredentials.password)
    .then(() => setSignInError(false))
    .catch(error => setSignInError(error.message))
  }

  const handlePasswordReset = () => {
    if (userCredentials.email) {
      firebase.auth().sendPasswordResetEmail(userCredentials.email).then(function() {
        setDialogText("A password reset email has been sent. Please check your email and follow the link provided.")
        setDialogOpen(true)
      }).catch(() => {
        setDialogText("Unable to send password reset email. Please try again later.")
        setDialogOpen(true)
      })
    } else {
      setEmailError('Please enter an email address')
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="Sunstate Petroleum" className={classes.logo} />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleLogin}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            type="email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleCredentials}
            error={!!emailError}
          />
          {emailError && <FormHelperText className={classes.error}>{emailError}</FormHelperText>}
          {passwordReset ? (
            <>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handlePasswordReset}
              >
                Send Password Reset Email
              </Button>
              <div className={classes.textRight}>
                <Link variant="body2" onClick={() => setPasswordReset(false)} style={{ cursor: 'pointer' }}>
                  Sign In
                </Link>
              </div>
              <DialogModal
                dialogOpen={dialogOpen}
                handleCloseDialog={() => {
                  setDialogOpen(false)
                  setDialogText('')
                  setPasswordReset(false)
                  setSignInError(false)
                }}
                text={dialogText}
                closeText="Ok"
              />
            </>
          ) : (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleCredentials}
                error={!!signInError}
              />
              {signInError && <FormHelperText className={classes.error}>{signInError}</FormHelperText>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <div className={classes.textRight}>
                <Link variant="body2" onClick={() => setPasswordReset(true)} style={{ cursor: 'pointer' }}>
                  Forgot password?
                </Link>
              </div>
            </>
          )}
        </form>
      </div>
      <Box mt={8}>
        <Footer variant="full" />
      </Box>
    </Container>
  )
}

export default SignIn
